import React from 'react';

import './About.css';
const Products = () => {
  return (
    <div>
      <div class='card mb-3'>
        <h5 class='card-header'>
          About GitHub Finder{' '}
          <span class='badge bg-secondary'>version : 1.0.1</span>
        </h5>
        <div class='card-body'>
          <h5 class='card-title'>Easily search github users or repository</h5>
          <p class='card-text'>
            Github finder is a React application which uses github REST api and
            helps to search a github user or repository. It can also helps you
            to download any repository in the zip format
          </p>
        </div>
      </div>
      <div class='card'>
        <h5 class='card-header bg-info'>About Me</h5>
        <div class='card-body'>
          <h5 class='card-title'>
            Mohammad Azaz <i class='fa fa-briefcase fa-fw '></i>Experience : 8.5
            yrs
          </h5>
          <div className='row'>
            <div className='col-md-4'>
              <img
                src='https://media.licdn.com/dms/image/C5103AQF2hrz1sO0KTA/profile-displayphoto-shrink_800_800/0/1553354102477?e=1685577600&v=beta&t=Qw9dbM2ZWsV9C3ACWey-713MQow8sUa8jWz5fUX41Tg'
                alt='profile-pic'
                className='card-img-center rounded'
                style={{ width: '50%', margin: '7px 10px' }}
              />
            </div>
            <div className='col-md-4'>
              Contact Details:
              <p>
                <i class='fa fa-briefcase fa-fw w3-margin-right w3-large w3-text-teal'></i>
                Full Stack Java Deveoper
              </p>
              <p>
                <i class='fa fa-home fa-fw w3-margin-right w3-large w3-text-teal'></i>
                Bangaore, India
              </p>
              <p>
                <i class='fa fa-envelope fa-fw w3-margin-right w3-large w3-text-teal'></i>
                <a href='mailto:mdazaz2010@gmail.com"'>mdazaz2010@gmail.com</a>
              </p>
              <p>
                <i class='fa fa-phone fa-fw w3-margin-right w3-large w3-text-teal'></i>
                +91- 7021154715
              </p>
              <hr></hr>
            </div>
            <div className='col-md-4'>
              <a
                href='https://www.linkedin.com/in/azaz1/'
                class='btn btn-info mb-4'
              >
                LinkedIn Profile <i className='fa fa-linkedin '></i>
              </a>
              <br />
              <a href='https://github.com/mdazaz' class='btn btn-secondary'>
                github profile <i className='fa fa-github '></i>
              </a>
            </div>
          </div>
          <hr />
          <p class='card-text'>
            Experienced Software Engineer with a demonstrated history of working
            in the information technology and services industry. Skilled in
            Java, C/C++, PHP,.NET Framework, Networking, Database, and C#.
            Worked in Indian Govt DRDO project for Indian defence system
            formely, Now working in Financial service (FISERV) software
            development. Strong engineering professional with a Master of
            Computer Applications (M.C.A.) focused in Computer Software
            Engineering from University of Mumbai.
          </p>
          <hr />
          <div class=''>
            <div class='w3-container w3-card w3-white w3-margin-bottom'>
              <h2 class='w3-text-grey w3-padding-16'>
                <i class='fa fa-suitcase fa-fw w3-margin-right w3-xxlarge w3-text-teal'></i>
                Work Experience
              </h2>
              <div class='w3-container'>
                <h5 class='w3-opacity'>
                  <b>Lead Technical Service / Manhattan Associates</b>
                </h5>
                <h6 class='w3-text-teal'>
                  <i class='fa fa-calendar fa-fw w3-margin-right'></i>March 2022
                  -<span class='w3-tag w3-teal w3-round'>Current</span>
                </h6>
                <p>
                  Leading the WM-PSO Team, working with leading ecommerce
                  company to provide supply chain management solution and
                  customized SCM Top
                  <h6>Key Skills:</h6>
                  <span class='badge bg-primary'>Java 8</span>
                  <span class='badge bg-secondary'>React</span>
                  <span class='badge bg-success'>Spring Boot</span>
                  <span class='badge bg-danger'>Hibernate</span>
                  <span class='badge bg-warning text-dark'>Aws cloud</span>
                  <span class='badge bg-info text-dark'>Velocity Template</span>
                  <span class='badge bg-light text-dark'>Mysql/Oracle</span>
                  <span class='badge bg-dark'>Postman api</span>
                </p>
                <hr />
              </div>
              <div class='w3-container'>
                <h5 class='w3-opacity'>
                  <b>Sr. Software Developer/ Fiserv</b>
                </h5>
                <h6 class='w3-text-teal'>
                  <i class='fa fa-calendar fa-fw w3-margin-right'></i>Mar 2019 -
                  Mar 2022
                </h6>
                <p>
                  I am working as Senior Software professional and handling
                  struts base Java application as well as c++ backend core
                  banking application. I have been good experience in Web
                  application lifecycle and have contributed as full stack
                  development of the application.
                  <br />
                  <h5>Domain Technologies:</h5>Banking Solutions, Card
                  Processing, ACH, Core Banking Solutions. Credit Union
                  Solutions.
                </p>
                <hr />
              </div>
              <div class='w3-container'>
                <h5 class='w3-opacity'>
                  <b>Software Developer / Tata Power SED</b>
                </h5>
                <h6 class='w3-text-teal'>
                  <i class='fa fa-calendar fa-fw w3-margin-right'></i>July 2014
                  - Feb 2019
                </h6>
                <p>
                  I have been working with client TATA Power SED as a software
                  developer since July 2014. My major role was software
                  development and debugging, testing and deployment as well
                  integration. I have worked on leading web technologies.
                </p>
                <br />
              </div>
            </div>

            <div class='w3-container w3-card w3-white'>
              <h2 class='w3-text-grey w3-padding-16'>
                <i class='fa fa-certificate fa-fw w3-margin-right w3-xxlarge w3-text-teal'></i>
                Education
              </h2>
              <div class='w3-container'>
                <h5 class='w3-opacity'>
                  <b>Udemy/Simplilearn </b>
                </h5>
                <h6 class='w3-text-teal'>
                  <i class='fa fa-calendar fa-fw w3-margin-right'></i>Forever
                </h6>
                <p>LifeLong Learning ! Keeps learning things onine</p>
                <hr />
              </div>
              <div class='w3-container'>
                <h5 class='w3-opacity'>
                  <b>University of Mumbai</b>
                </h5>
                <h6 class='w3-text-teal'>
                  <i class='fa fa-calendar fa-fw w3-margin-right'></i>2010 -
                  2013
                </h6>
                <p>Masters in Computer Applications (MCA)</p>
                <hr />
              </div>
              <div class='w3-container'>
                <h5 class='w3-opacity'>
                  <b>A.N College Patna (Magadha University)</b>
                </h5>
                <h6 class='w3-text-teal'>
                  <i class='fa fa-calendar fa-fw w3-margin-right'></i>2007 -
                  2010
                </h6>
                <p>Bachelor in Computer Applications</p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Products;
