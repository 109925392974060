import React from 'react';
import {
  json,
  useLoaderData,
  useNavigation,
  useActionData,
} from 'react-router-dom';
import SearchForm from '../components/SearchForm';
import Spinner from '../components/Spinner';
import UserItem from '../components/UserItem';

const UsersPage = () => {
  let users = useLoaderData();
  let searchUser = useActionData();
  if (searchUser) {
    users = searchUser;
    // console.log(searchUser);
  }
  const loading = useNavigation().state === 'loading';
  const SearchType = 'users';

  //console.log(users);
  return (
    <div>
      <SearchForm SearchType={SearchType} />
      {loading && <Spinner />}
      {!loading && (
        <div className='row'>
          {users.map((user) => (
            <UserItem key={user.id} user={user} />
          ))}
        </div>
      )}
    </div>
  );
};

export default UsersPage;
export async function Loader() {
  //setLoading(true);
  const url = 'https://api.github.com/users';
  const method = 'GET';
  const response = await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    //body: JSON.stringify(),
  });
  //setLoading(false);
  if (response.status === 422 || response.status === 401) {
    return response;
  }

  if (!response.ok) {
    throw json(
      { message: 'Error ocurred while fatching default Users.' },
      { status: 500 }
    );
  }
  const resData = await response.json();
  //console.log(response);
  return resData;
}

export async function userSearchAction({ request, params }) {
  //const method = request.method;
  const data = await request.formData();
  const searchTxt = data.get('text');
  const url = `https://api.github.com/search/users?q=${searchTxt}&client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&client_secret=${process.env.REACT_APP_GITHUB_CLIENT_SECRET}`;
  const method = 'GET';
  const response = await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    //body: JSON.stringify(),
  });
  if (response.status === 422 || response.status === 401) {
    throw json(
      { message: 'Error ocurred while fatching Users.' },
      { status: 422 }
    );
  }

  if (!response.ok) {
    throw json(
      { message: 'Error ocurred while fatching Users.' },
      { status: 500 }
    );
  }
  const resData = await response.json();
  console.log(resData);
  return resData.items;
}
