import React from 'react';
import { Link } from 'react-router-dom';

const UserItem = ({ user }) => {
  return (
    <div className='col-sm-2'>
      <div
        className='card border-info mb-5'
        style={{ backgroundColor: '#EEE' }}
      >
        <div className='card-title'>
          <img
            className='card-img-center rounded-circle'
            src={user.avatar_url}
            alt='avatar'
            style={{ width: '60px', margin: '7px 10px' }}
          />
          <a
            href={user.html_url}
            style={{
              color: 'blue',
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
          >
            <span>{user.login}</span>
          </a>
        </div>
        <div className='card-body'>
          <p className='card-text'>{user.type}</p>
          <Link to={`/user/${user.login}`} className='btn btn-primary btn-sm'>
            Know More..
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserItem;
