import React, { useEffect, useState } from 'react';
import { useParams, json, Link, useLoaderData } from 'react-router-dom';
import Spinner from '../components/Spinner';

const RepoDetailPage = () => {
  const userId = useParams().userId;
  console.log('userId' + userId);
  const repoId = useParams().repoId;
  console.log('repoId' + repoId);

  const repoDetails = useLoaderData();
  console.log('repoDetails:' + repoDetails);

  /*
  useEffect(
    () =>
      async function fechRepo() {
        const url = `https://api.github.com/repos/${userId}/${repoId}`;
        setLoading(true);
        const response = await fetch(url, { method: 'GET' });
        if (response.status === 422 || response.status === 401) {
          return response;
        }

        if (!response.ok) {
          throw json(
            { message: 'Error ocurred while fatching tweet feeds.' },
            { status: 500 }
          );
        }
        const resData = await response.json();

        setRepoDetails(resData);
        setLoading(false);
        //console.log(resData);
      },
    []
  );
  */
  const download_url =
    repoDetails.svn_url +
    '/archive/refs/heads/' +
    repoDetails.default_branch +
    '.zip';

  return (
    <div>
      <Link to='/repos' className='btn btn-secondary'>
        <i className='fa fa-arrow-left' aria-hidden='true'></i> Back to Repo
        Search
      </Link>
      <br />
      <br />

      <div className='card mb-3' style={{ width: '50rem' }}>
        <div className='row g-0'>
          <div>
            <h2 className='card-title'>{repoDetails.full_name}</h2>
            <span
              className='badge bg-secondary tex-light'
              style={{ marginLeft: '20px' }}
            >
              {repoDetails.visibility}
            </span>
            <span style={{ marginLeft: '20px' }}>
              <i className='fa fa-star-o'></i>
              {repoDetails.stargazers_count}
            </span>
            <span style={{ marginLeft: '20px' }}>
              <i className='fa fa-code-fork'></i>
              {repoDetails.forks_count}
            </span>
          </div>

          <div className='col-md-4'>
            <div className='card-body'>
              <p>
                <Link to={repoDetails.clone_url}>Clone Repo</Link>
              </p>
              <p>
                <Link to={download_url}>
                  <button className='btn btn-danger'>
                    Download .zip <i class='fa fa-download'></i>
                  </button>
                </Link>
              </p>

              <p>
                <span className='h6'>Language:</span>
                {repoDetails.language}
              </p>
              <p>
                <span className='h6'>Created on:</span>
                {Date(repoDetails.created_at).toString()}
              </p>
              <p>
                <span className='h6'>Updated on:</span>
                {Date(repoDetails.updated_at)}
              </p>
            </div>
          </div>
          <div className='col-md-8'>
            <div className='card-body'>
              <p>
                <span className='h6'>Wiki: </span>
                {repoDetails.has_wiki ? (
                  <Link
                    to={`${repoDetails.html_url}/wiki`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    View Wiki
                  </Link>
                ) : (
                  'No'
                )}
                <span className='h6' style={{ marginLeft: '2rem' }}>
                  <Link
                    to={`${repoDetails.html_url}/issues`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    issues
                  </Link>
                </span>
              </p>

              {repoDetails && (
                <p className='card-text'>
                  <h5>Description:</h5>
                  {repoDetails.description}
                </p>
              )}
              {repoDetails.owner && (
                <h6 className='card-text'>
                  Owner:
                  <img
                    className='card-img-center rounded-circle'
                    style={{ width: '60px', margin: '7px 10px' }}
                    src={repoDetails.owner.avatar_url}
                    alt='owner_dp'
                  />
                  <Link to={`/user/${repoDetails.owner.login}`}>
                    {repoDetails.owner.login}
                  </Link>
                </h6>
              )}
              <p>
                <span className='h6'>Open Isuue:</span>
                {repoDetails.open_issues_count}
                <span className='h6' style={{ marginLeft: '2rem' }}>
                  Branch:{' '}
                </span>
                {repoDetails.default_branch}
              </p>

              <h6 className='card-text'>License:</h6>
              <p>{repoDetails.license && repoDetails.license.name}</p>

              <a href={repoDetails.html_url} className='btn btn-info'>
                Visit on Github
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepoDetailPage;
export async function Loader({ request, params }) {
  const userId = params.userId;
  const repoId = params.repoId;

  const url = `https://api.github.com/repos/${userId}/${repoId}`;
  //setLoading(true);
  const response = await fetch(url, { method: 'GET' });
  if (response.status === 422 || response.status === 401) {
    return response;
  }

  if (!response.ok) {
    throw json(
      { message: 'Error ocurred while fatching tweet feeds.' },
      { status: 500 }
    );
  }
  const resData = await response.json();

  // setRepoDetails(resData);
  // setLoading(false);
  //alert(repoId);
  console.log(resData);
  return resData;
}
