import React from 'react';
import { json, useActionData, useNavigation } from 'react-router-dom';
import RepoItem from '../components/RepoItem';
import SearchForm from '../components/SearchForm';
import Spinner from '../components/Spinner';

const ReposPage = () => {
  let loading = true;
  let repos = useActionData();
  loading = useNavigation().state === 'loading';
  return (
    <>
      <SearchForm />
      {loading && <Spinner />}
      {!loading && repos && repos.length > 0 && (
        <p className='h2 alert alert-info'>
          Showing first {repos.length} Repository{' '}
        </p>
      )}
      <div className='row'>
        {repos && repos.map((repo) => <RepoItem repo={repo} key={repo.id} />)}
        {(loading = false)}
      </div>
    </>
  );
};

export default ReposPage;

export async function reposSearchAction({ request, params }) {
  //const method = request.method;
  const data = await request.formData();
  const searchTxt = data.get('text');
  const url = `https://api.github.com/search/repositories?q=${searchTxt}&per_page=100&sort=created:asc&client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&client_secret=${process.env.REACT_APP_GITHUB_CLIENT_SECRET}`;
  const method = 'GET';
  const response = await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    //body: JSON.stringify(),
  });
  if (response.status === 422 || response.status === 401) {
    return response;
  }

  if (!response.ok) {
    throw json(
      { message: 'Error ocurred while fatching Users.' },
      { status: 500 }
    );
  }
  const resData = await response.json();
  console.log(searchTxt);

  alert('Total Result Found:' + resData.total_count);
  return resData.items;
}
