import React, { useState } from 'react';
import { useLoaderData, json, Link } from 'react-router-dom';
import RepoItem from '../components/RepoItem';

const UserDetailPage = () => {
  const userDetail = useLoaderData();
  const [repos, setRepos] = useState([]);
  //console.log(userDetail);
  const {
    name,
    avatar_url,
    location,
    followers,
    following,
    bio,
    blog,
    login,
    html_url,
    company,
    public_repos,
    public_gists,
    hireable,
  } = userDetail;

  const getUserReposHandler = async () => {
    const url = `https://api.github.com/users/${userDetail.login}/repos?per_page=20&sort=created:asc&client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&client_secret=${process.env.REACT_APP_GITHUB_CLIENT_SECRET}`;
    console.log(url);
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // this.setState({ repos: res.data, loading: false });
    const resData = await res.json();
    //console.log(resData);
    setRepos(resData);
  };
  return (
    <div>
      <Link to='/users' className='btn btn-secondary'>
        <i className='fa fa-arrow-left' aria-hidden='true'></i> Back to user
        list
      </Link>
      <br />
      Hirable:{''}
      {hireable ? 'Yes' : 'No'}
      <div className='card mb-3' style={{ width: '50rem' }}>
        <div className='row g-0'>
          <div className='col-md-4'>
            <img
              src={avatar_url}
              alt={login}
              className='card-img-center rounded'
              style={{ width: '200px', margin: '7px 10px' }}
            />
          </div>
          <div className='col-md-8'>
            <div className='card-body'>
              <h5 className='card-title'>{name}</h5>
              <p className='card-text'>{location}</p>

              {bio && (
                <p className='card-text'>
                  <h4>Bio:</h4>
                  {bio}
                </p>
              )}
              {company && (
                <h5 className='card-text'>
                  Company:
                  {company}
                </h5>
              )}
              {blog && (
                <h5 className='card-text'>
                  Website:
                  {blog}
                </h5>
              )}
              <a href={html_url} className='btn btn-danger'>
                Visit on Github
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='card text-center' style={{ width: '50rem' }}>
        <div className='card-body row'>
          <div className='col-md-3 lead'>
            Followers:<span className='badge bg-warning'>{followers}</span>
          </div>
          <div className='col-md-3 lead'>
            Followings:<span className='badge bg-dark'>{following}</span>
          </div>
          <div className='col-md-3 lead'>
            Public Repos:
            <span className='badge bg-info'>{public_repos}</span>
          </div>
          <div className='col-md-3 lead'>
            Public Gist:<span className='badge bg-danger'>{public_gists}</span>
          </div>
        </div>
      </div>
      <hr />
      <button className='btn btn-info' onClick={getUserReposHandler}>
        Show User Repositories
      </button>
      <div className='row'>
        {repos && repos.map((repo) => <RepoItem repo={repo} key={repo.id} />)}
      </div>
    </div>
  );
};

export default UserDetailPage;

export async function Loader({ params, request }) {
  const username = params.userId;

  const url = 'https://api.github.com/users/' + username;
  const method = 'GET';
  const response = await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    //body: JSON.stringify(),
  });

  if (response.status === 422 || response.status === 401) {
    return response;
  }

  if (!response.ok) {
    throw json(
      { message: 'Error ocurred while fatching tweet feeds.' },
      { status: 500 }
    );
  }
  const resData = await response.json();

  return resData;
}
