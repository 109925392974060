import React from 'react';
import { Outlet } from 'react-router-dom';
import MainNavigation from '../components/MainNavigation';

const RootLayout = () => {
  return (
    <div>
      <MainNavigation />
      <main className='container'>
        <Outlet />
      </main>
    </div>
  );
};

export default RootLayout;
