import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <Link
            to={'/repos'}
            className='card col-sm-6'
            style={{ width: '18rem', margin: 'auto' }}
          >
            <div className='card-img-top text-center' alt='...'>
              <i className='fa fa-file-code-o' style={{ fontSize: '48px' }}></i>
            </div>
            <div className='card-body'>
              <h5 className='card-title'>Search Github for Repository</h5>
            </div>
          </Link>

          <Link
            to={'/users'}
            className='card col-sm-6'
            style={{ width: '18rem', margin: 'auto' }}
          >
            <div className='card-img-top text-center' alt='...'>
              <i
                className='fa fa-user'
                aria-hidden='true'
                style={{ fontSize: '48px' }}
              ></i>
            </div>
            <div className='card-body'>
              <h5 className='card-title'>You can search Github User</h5>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
