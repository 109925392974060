import React from 'react';
import { Link } from 'react-router-dom';

const RepoItem = ({ repo }) => {
  var options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  let repo_updated_dt = new Date(repo.updated_at);
  return (
    <div className='col-sm-3'>
      <div className='card' style={{ marginBottom: '20px' }}>
        <div className='card-body'>
          <h5 className='card-title'>
            <Link to={`/repos/${repo.owner.login}/${repo.name}`}>
              {repo.name}
            </Link>
            <span
              className='badge bg-secondary tex-light'
              style={{ marginLeft: '20px' }}
            >
              {repo.visibility}
            </span>
            <span style={{ marginLeft: '20px' }}>
              <i className='fa fa-star-o'></i>
              {repo.stargazers_count}
            </span>
            <span style={{ marginLeft: '20px' }}>
              <i className='fa fa-code-fork'></i>
              {repo.forks_count}
            </span>
            <hr />
          </h5>
          <div className='card-text'>
            <p className='fw-bold'>
              <i className='fa fa-user' aria-hidden='true'></i>{' '}
              <span>
                <Link to={`/user/${repo.owner.login}`}>{repo.owner.login}</Link>
              </span>
            </p>

            <p>
              <span className='fw-bold'>Description : </span>
              {repo.description}{' '}
            </p>
            <p>
              <span className='fw-bold'>Language: </span>
              <span>{repo.language}</span>
            </p>
            <p>
              <span className='fw-bold'>Updated:</span>
              {repo_updated_dt.toLocaleDateString('en-US', options)}
            </p>
            <hr />
            <Link
              to={`/repos/${repo.owner.login}/${repo.name}`}
              className='btn btn-info'
            >
              View Details
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepoItem;
