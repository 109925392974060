import React from 'react';
import { Form } from 'react-router-dom';

const SearchForm = ({ SearchType }) => {
  return (
    <div>
      <fieldset className='border p-2 bg-info rounded'>
        <Form method='post' className='row g-3 form mt-3'>
          <div
            className='col-md-3 lead text-end'
            style={{ color: 'black', fontWeight: 'bold' }}
          >
            Enter any {SearchType === 'users' ? "User's Name" : 'Repository'}:
          </div>
          <div className='col-md-5'>
            <input
              type='text'
              name='text'
              placeholder={`Search ${
                SearchType === 'users' ? 'a User..' : 'a Repository..'
              }`}
              className='form-control'
            />
          </div>
          <div className='col-auto'>
            <input
              type='submit'
              value='Search'
              className='btn btn-primary mb-3'
            />
          </div>
          <div className='col-auto'></div>
        </Form>
      </fieldset>

      <hr />
    </div>
  );
};

export default SearchForm;
