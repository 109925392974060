import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './App.css';
import HomePage from './pages/Home';
import About from './pages/About';
import RootLayout from './pages/Root';
import ErrorPage from './pages/Error';
import ReposPage, { reposSearchAction } from './pages/Repos';
import RepoDetailPage, { Loader as repoDetailLoader } from './pages/RepoDetail';
import UsersPage, {
  Loader as UsersLoader,
  userSearchAction,
} from './pages/Users';
import UserDetailPage, { Loader as UserDetailLoader } from './pages/UserDetail';

function App() {
  const rout = createBrowserRouter([
    {
      path: '/',
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      children: [
        { index: true, element: <HomePage /> },
        {
          path: 'users',
          element: <UsersPage />,
          loader: UsersLoader,
          action: userSearchAction,
        },

        {
          path: '/user/:userId',
          id: 'user-detail',
          //loader: eventDetailLoader,
          element: <UserDetailPage />,
          loader: UserDetailLoader,
        },
        {
          path: 'repos',
          element: <ReposPage />,
          action: reposSearchAction,
        },

        {
          path: '/repos/:userId/:repoId',
          id: 'repo-detail',
          //action: fechRepoAction,
          loader: repoDetailLoader,
          element: <RepoDetailPage />,
        },
        { path: '/about-us', element: <About /> },
      ],
    },
  ]);

  return <RouterProvider router={rout} />;
}

export default App;
