import { NavLink, useRouteLoaderData } from 'react-router-dom';
import '../index.css';

function MainNavigation() {
  //const token = useRouteLoaderData('root');
  const showSearch = false;
  return (
    <div>
      <nav className='navbar navbar-expand-md navbar-dark bg-dark mb-4'>
        <div className='container-fluid'>
          <a className='navbar-brand' href='/'>
            <i className='fa fa-github '></i> Github Finder
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarCollapse'
            aria-controls='navbarCollapse'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarCollapse'>
            <ul className='navbar-nav me-auto mb-2 mb-md-0'>
              <li className='nav-item nav-link'>
                <NavLink
                  to='/'
                  className={({ isActive }) =>
                    isActive ? 'active' : undefined
                  }
                  end
                >
                  Home
                </NavLink>
              </li>
              <li className='nav-item nav-link'>
                <NavLink
                  to='/users'
                  className={({ isActive }) =>
                    isActive ? 'active' : undefined
                  }
                >
                  Users
                </NavLink>
              </li>
              <li className='nav-item nav-link'>
                <NavLink
                  to='/repos'
                  className={({ isActive }) =>
                    isActive ? 'active' : undefined
                  }
                >
                  Repositories
                </NavLink>
              </li>
              <li className='nav-item nav-link'>
                <NavLink
                  to='/about-us'
                  className={({ isActive }) =>
                    isActive ? 'active' : undefined
                  }
                >
                  About
                </NavLink>
              </li>
            </ul>
            {showSearch && (
              <form className='d-flex input-group w-auto'>
                <input
                  type='search'
                  className='form-control'
                  placeholder='Type query'
                  aria-label='Search'
                />
                <button
                  className='btn btn-outline-primary'
                  type='button'
                  data-mdb-ripple-color='dark'
                >
                  Search
                </button>
              </form>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default MainNavigation;
